import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import HandelMember from "./HandelMember";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrgpartnerProfile } from "../../features/Profile/ProfileSlice";
import { Link } from "react-router-dom";

const Desktop = () => {
  const dispatch = useDispatch();
  const orgPartners = useSelector(
    (store) => store?.profile?.orgPartners?.content
  );
  const [partners, setpartners] = useState(orgPartners);
  const [isManaging, setManaging] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [manage, setManage] = useState(null);

  const handleManaging = (id) => {
    if (id) {
      setManage(id);
    } else {
      setManage(null);
    }

    setManaging((st) => !st);
  };
  console.log(orgPartners);
  // serach
  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };

  useEffect(() => {
    if (searchName) {
      setpartners(
        orgPartners?.filter(
          (i) =>
            i?.partnerFirstName
              ?.toLowerCase()
              ?.includes(searchName?.toLowerCase()) ||
            i?.partnerLastName
              ?.toLowerCase()
              ?.includes(searchName?.toLowerCase())
        )
      );
    } else {
      setpartners(orgPartners);
    }
  }, [searchName]);
  useEffect(() => {
    setpartners(orgPartners);
  }, [orgPartners]);
  useEffect(() => {
    dispatch(fetchOrgpartnerProfile());
  }, []);
  return (
    <div className="min-h-screen px-[1vw]  pt-1">
      {/* Popups */}
      {isManaging && (
        <HandelMember memberId={manage} handleManaging={handleManaging} />
      )}
      <div className="flex flex-col gap-[0.5vw]">
        {/* Heading */}
        <div className="rounded-xl flex px-[4%] w-full m-auto py-5 justify-between items-center stShadow bg-white ">
          <h1 className="text-xl font-semibold">Account Members</h1>
          <button
            className="text-primary-max font-medium flex items-center gap-2"
            onClick={() => handleManaging()}>
            <IoMdAdd className="text-3xl" /> Add Member
          </button>
        </div>
        {/* Filters */}
        <div className="w-full stShadow flex gap-[3%] px-[4%] py-5 rounded-xl items-center bg-white ">
          <div className=" border rounded-md overflow-hidden">
            <input
              type="search"
              placeholder="Search by name "
              value={searchName}
              onChange={handleSearch}
              className="py-[4%] px-[8%] capitalize"
            />
          </div>
          <div className=" items-center gap-[0.5vw] hidden">
            <select
              name="department"
              id="departnment"
              defaultValue={"0"}
              className=" px-[6%] py-[4%]  border rounded-md">
              <option value="0" disabled>
                <span className="text-gray-200 ">Select Department..</span>
              </option>
              <option value="1">ABC</option>
            </select>
          </div>
          <div className=" items-center hidden">
            <div className="relative">
              <span className="px-1 bg-white absolute -top-[0.6vw] left-2 text-[0.8vw]">
                From
              </span>
              <input
                type="date"
                className="px-[3%] py-[3%]  border rounded-md text-gray-400  "
              />
            </div>
            <div className="border-b-2 w-4"></div>
            <div className="relative">
              <span className="px-1 bg-white absolute -top-[0.6vw] left-2 text-[0.8vw]">
                To
              </span>
              <input
                type="date"
                className="px-[3%] py-[3%]  border rounded-md text-gray-400  "
              />
            </div>
          </div>

          <div className="flex justify-between items-center w-[14%]">
            <button className="py-[4%] px-[3%] bg-[#000] rounded-md text-white font-semibold ">
              <CiSearch className="text-xl" />
            </button>
          </div>
        </div>

        {/* members List */}
        {partners?.map((elem, index) => {
          return (
            <Link
              to={`/accountmember/details/${elem?.id}`}
              className="px-[4%] stShadow py-[1%] bg-white rounded-xl"
              key={index}>
              <div className=" flex items-center justify-between ">
                <div className="w-2/3 flex items-center justify-between px-[1%]">
                  <img
                    src="https://www.1training.org/wp-content/uploads/2021/04/Car-Mechanic-Diploma-Level-4.jpg"
                    alt=""
                    className="h-[4vw] w-[4vw] object-cover"
                  />

                  <div className="text-[1.5vw] max-[1000px]:text-[1.8vw]">
                    {elem?.partnerFirstName + " " + elem?.partnerLastName}
                  </div>
                  <div>{elem?.skillSet}</div>
                  <div className="font-semibold">{elem?.partnerMobile}</div>
                </div>
                <HiOutlineDotsVertical className="text-[1.5vw] max-[1000px]:text-[1.8vw]" />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Desktop;
