import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BookingCards from "./Home/BookingCards";
import { Link, useLocation } from "react-router-dom";

const BookingsList = () => {
  const location = useLocation();
  const [bookingType, setBookingType] = useState("future");
  const [bookingsData, setBookingData] = useState();
  const { orgPartnerBookings } = useSelector((store) => store.booking);

  const handleTypeChange = (type) => {
    setBookingType(type);
  };

  useEffect(() => {
    setBookingData(
      orgPartnerBookings?.filter(
        (elem) =>
          elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
          bookingType
      )
    );
  }, [bookingType, orgPartnerBookings]);
  return (
    <div className="flex flex-col gap-[0.625rem] pt-3  h-full ">
      <div className="pb-1 border-b border-black flex justify-between ">
        <h1 className="text-xl font-bold">Bookings</h1>
        <div className="flex gap-3 text-xs font-medium ">
          {bookingType === "future" ? (
            <button className=" px-5 py-2 rounded-xl bg-primary-max text-white">
              Upcomming
            </button>
          ) : (
            <button
              className=" px-5 py-2 rounded-xl bg-[#D5F7FF]"
              onClick={() => handleTypeChange("future")}>
              Upcomming
            </button>
          )}
          {bookingType === "ongoing" ? (
            <button className=" px-5 py-2 rounded-xl bg-primary-max text-white">
              Ongoing
            </button>
          ) : (
            <button
              className=" px-5 py-2 rounded-xl bg-[#D5F7FF]"
              onClick={() => handleTypeChange("ongoing")}>
              Ongoing
            </button>
          )}
          {bookingType === "completed" ? (
            <button className=" px-5 py-2 rounded-xl bg-primary-max text-white">
              Completed
            </button>
          ) : (
            <button
              className=" px-5 py-2 rounded-xl bg-[#D5F7FF]"
              onClick={() => handleTypeChange("completed")}>
              Completed
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between flex-1">
        <BookingCards bookings={bookingsData?.slice(0, 5)} />
        {location.pathname === "/" && (
          <Link
            to={
              bookingType === "completed"
                ? "/bookings/completed"
                : bookingType === "ongoing"
                ? "/#"
                : "/bookings/upcoming"
            }
            className="p-2 underline mx-auto hover:bg-gray-50 cursor-pointer">
            Show More
          </Link>
        )}
      </div>
    </div>
  );
};

export default BookingsList;
